import { render, staticRenderFns } from "./PTip.vue?vue&type=template&id=fae92736&scoped=true&"
import script from "./PTip.vue?vue&type=script&lang=js&"
export * from "./PTip.vue?vue&type=script&lang=js&"
import style0 from "./PTip.vue?vue&type=style&index=0&id=fae92736&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fae92736",
  null
  
)

export default component.exports