<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-header">
        <div>
          <label>所属税期：</label>
          <el-date-picker
            v-model="taxPeriod"
            value-format="yyyyMM"
            :picker-options="{ disabledDate }"
            type="month"
            placeholder="选择月"
          >
          </el-date-picker>
          <div class="items">
            <el-button type="default" @click="handleReset">重置</el-button>
            <el-button type="primary" @click="handleSelectHistory"
              >查询</el-button
            >
          </div>
        </div>
        <div>
          <el-button type="default" @click="handlePrint">打印</el-button>
        </div>
      </div>
    </div>
    <div class="content-main">
      <div class="table-header">
        <div style="width: 470px">抵扣统计表</div>
        <div class="header-right">
          <div>
            <label>组织名称：</label>
            <span>{{ deductAuthorize.name }}</span>
          </div>
          <div>
            <label>组织税号：</label>
            <span>{{ deductAuthorize.taxNo }}</span>
          </div>
          <div>单位:份、元</div>
        </div>
      </div>
      <div class="table-content">
        <el-table
          :data="tableData"
          v-loading="tableLoading"
          stripe
          border
          show-summary
          :summary-method="getSummaries"
          :header-cell-style="handleHeaderCellStyle"
          style="width: 100%"
        >
          <el-table-column label="用途" width="160">
            <el-table-column prop="invoiceType" label="发票类型" width="160">
              <template slot-scope="scope">
                <span>{{
                  handleValueToLabel("DeductInvoiceType", scope.row.invoiceType)
                }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="抵扣">
            <el-table-column prop="deductCount" label="份数"></el-table-column>
            <el-table-column prop="deductAmount" label="金额">
              <template slot-scope="scope">
                <div>{{ "￥" + scope.row.deductAmount }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="deductValidTaxAmount" label="有效税额">
              <template slot-scope="scope">
                <div>{{ "￥" + scope.row.deductValidTaxAmount }}</div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="不抵扣">
            <el-table-column
              prop="noDeductCount"
              label="份数"
            ></el-table-column>
            <el-table-column prop="noDeductAmount" label="金额">
              <template slot-scope="scope">
                <div>{{ "￥" + scope.row.noDeductAmount }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="noDeductValidTaxAmount" label="有效税额">
              <template slot-scope="scope">
                <div>{{ "￥" + scope.row.noDeductValidTaxAmount }}</div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { tableStyle, changeMethods, dateOption } from "../../util/mixins";
import { getDeductIncomeStatistics } from "@/service/deduction";
import DeductInvoiceType from "@/components/widgets/DeductInvoiceType";

export default {
  name: "HistoryTaxPeriodStatistics",
  mixins: [tableStyle, changeMethods, dateOption],
  components: { DeductInvoiceType },
  data() {
    return {
      stage: "",
      taxPeriod: "",
      deductAuthorize: {},
      tableLoading: false,
      tableData: [],
    };
  },
  created() {
    this.deductAuthorize = this.$store.state.deductAuthorize;
    console.log(JSON.stringify(this.deductAuthorize));
    this.handleSelectHistory();
  },
  methods: {
    // 重置表单
    handleReset() {
      this.taxPeriod = "";
    },
    // 打印
    handlePrint() {
      window.print();
    },
    // 格式化所属税期
    fmtTaxPeriod(val) {
      if (val) {
        let arr = val.split("");
        arr.splice(6, 0, "月");
        arr.splice(4, 0, "年");
        return arr.join("");
      } else {
        return "--";
      }
    },
    // 历史所属期统计表查询
    async handleSelectHistory() {
      if (!this.taxPeriod || !this.deductAuthorize.taxNo) {
        return;
      }
      this.tableLoading = true;
      const { success, data } = await getDeductIncomeStatistics({
        taxNo: this.deductAuthorize.taxNo,
        currentTaxPeriod: this.taxPeriod,
      });
      this.tableLoading = false;
      if (success) this.tableData = data;
    },
    /* 格式化合计行 */
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (
          !values.every((value) => isNaN(value)) &&
          [2, 3, 5, 6].indexOf(index) > -1
        ) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            return !isNaN(value) ? Number((prev + curr).toFixed(2)) : prev;
          }, 0);
          sums[index] = "￥" + sums[index];
        } else {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            return !isNaN(value) ? Number((prev + curr).toFixed(2)) : prev;
          }, 0);
          sums[index] = sums[index];
        }
      });
      return sums;
    },
    disabledDate(time) {
      let dqssq = this.deductAuthorize ? this.deductAuthorize.dqssq : "";
      if (!dqssq) {
        dqssq = this.dateFormat(new Date().toLocaleString(), "YYYYMM");
      }
      return this.dateFormat(time.toLocaleString(), "YYYYMM") >= dqssq;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-select {
  color: #666666;
  font-size: 16px;
  box-shadow: none;

  .select-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .items {
      display: inline-block;
      margin-left: 24px;
    }
  }
}

.content-main {
  margin: 0;
  padding: 32px 0;
}

.table-header {
  display: flex;
  color: #666666;
  font-size: 16px;
  padding: 9px 12px;
  flex-direction: row;
  align-items: center;
  border: 1px solid #eeeeee;
  justify-content: space-between;

  .header-right {
    flex: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      color: #333333;
    }
  }
}

::v-deep .el-table thead.is-group tr:first-of-type th:first-of-type {
  border-bottom: none;
  padding-left: 60px;
}

::v-deep .el-table thead.is-group tr:last-of-type th:first-of-type {
  padding-right: 60px;
}

::v-deep .el-table thead.is-group tr:first-of-type th:first-of-type:before {
  top: 0;
  left: 0;
  width: 1px;
  content: "";
  height: 97px;
  display: block;
  position: absolute;
  transform-origin: top;
  background-color: #ffffff;
  transform: rotate(-59deg);
}

::v-deep .el-table thead.is-group tr:last-of-type th:first-of-type:before {
  right: 0;
  bottom: 0;
  width: 1px;
  content: "";
  height: 97px;
  display: block;
  position: absolute;
  transform-origin: bottom;
  background-color: #ffffff;
  transform: rotate(-59deg);
}
</style>