<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-content">
        <el-form class="select-form" ref="queryForm" :inline="true" :model="queryForm" label-width="100px" @keyup.enter.native="submitForm">
          <el-form-item label="所属税期" prop="confirmTaxPeriod">
            <el-date-picker clearable v-model="queryForm.confirmTaxPeriod" type="month" value-format="yyyyMM" placeholder="请选择所属税期"> </el-date-picker>
          </el-form-item>
          <el-form-item label="发票号码" prop="invoiceNo">
            <el-input clearable v-model.trim="queryForm.invoiceNo" placeholder="请输入发票号码" maxlength="8" @keyup.enter.native="getInvoiceList()" />
          </el-form-item>
          <el-form-item label="发票代码" prop="invoiceCode">
            <el-input clearable v-model.trim="queryForm.invoiceCode" placeholder="请输入发票代码" maxlength="12" @keyup.enter.native="getInvoiceList()" />
          </el-form-item>
          <el-form-item label="数电票号" prop="eInvoiceNo">
            <el-input clearable v-model.trim="queryForm.eInvoiceNo" placeholder="请输入数电票号" maxlength="20" @keyup.enter.native="getInvoiceList()" />
          </el-form-item>
          <el-form-item label="发票类型" prop="invoiceType" v-show="selectExpended">
            <el-select clearable v-model="queryForm.invoiceType" placeholder="请选择发票类型">
              <el-option v-for="item in invoiceTypes" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="销方名称" prop="sellerName" v-show="selectExpended">
            <el-input clearable v-model.trim="queryForm.sellerName" placeholder="请输入销方名称" maxlength="32" @keyup.enter.native="getInvoiceList()" />
          </el-form-item>
          <el-form-item label="开票日期" prop="invoiceTime" v-show="selectExpended">
            <el-date-picker clearable class="range-picker" v-model="invoiceTimeRange" start-placeholder="开始时间" end-placeholder="结束时间" type="daterange" size="mini" range-separator="~" value-format="yyyy-MM-dd" :picker-options="dateOption"> </el-date-picker>
          </el-form-item>
          <el-form-item label="勾选日期" prop="checkedTime" v-show="selectExpended">
            <el-date-picker clearable class="range-picker" v-model="checkedTimeRange" start-placeholder="开始时间" end-placeholder="结束时间" type="daterange" size="mini" range-separator="~" value-format="yyyy-MM-dd" :picker-options="dateOption"> </el-date-picker>
          </el-form-item>
          <el-form-item style="margin-left: 32px"><select-button show-status @list-close="selectExpended = false" @list-show="selectExpended = true" @select="handleQuery" @reset="resetForm"></select-button></el-form-item>
        </el-form>
      </div>
    </div>
    <div class="spilt-line"></div>
    <div class="content-main">
      <div class="main-top">
        <p-header :title="'认证清单'">
          <el-button type="primary" style="float: right" @click="printTable" v-if="tableSums.total">打印清单</el-button>
          <el-button type="primary" style="float: right" v-loading="dataLoading" @click="InvoiceExportBatch">批量导出</el-button>
        </p-header>
      </div>
      <div class="content-table">
        <el-table ref="invoiceCenterTable" :data="tableData" stripe border v-loading="dataLoading" :header-cell-style="handleHeaderCellStyle" @selection-change="handleSelection" style="width: 100%">
          <el-table-column type="selection" width="40" />
          <el-table-column type="index" label="序号" width="60" fixed="left">
            <template slot-scope="scope">{{ (page.current - 1) * page.size + (scope.$index + 1) }} </template>
          </el-table-column>
          <el-table-column prop="invoiceType" label="发票类型" min-width="160"></el-table-column>
          <el-table-column prop="invoiceCode" label="发票代码" min-width="160" fixed="left"></el-table-column>
          <el-table-column prop="invoiceNo" label="发票号码" min-width="160" fixed="left"></el-table-column>
          <el-table-column prop="eInvoiceNo" label="数电发票号码" min-width="160"></el-table-column>

          <el-table-column prop="invoiceTime" label="开票日期" min-width="200"></el-table-column>
          <el-table-column prop="sellerName" label="销售方名称" min-width="160"></el-table-column>
          <el-table-column label="金额" min-width="120">
            <template slot-scope="scope">
              <span>{{ fmtData(scope.row.amount) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="税额" min-width="120">
            <template slot-scope="scope">
              <span>{{ fmtData(scope.row.taxAmount) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="有效税额" min-width="120">
            <template slot-scope="scope">
              <span>{{ fmtData(scope.row.deductTaxAmount) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="confirmTaxPeriod" label="所属税期" min-width="120"></el-table-column>
          <el-table-column prop="checkedTime" label="勾选日期" min-width="200"></el-table-column>
        </el-table>
        <div class="table-statistics" v-loading="sumsLoading">
          <div class="statistics-left">
            <div>
              已选发票：<span style="color: red">{{ selections.length }}</span
              >&nbsp;张
            </div>
          </div>
          <div class="statistics-right">
            <div>发票数量：{{ tableSums.total }}&nbsp;张</div>
            <div>发票税额：￥{{ formatMoney(tableSums.taxAmount) }}</div>
            <div>有效税额：￥{{ formatMoney(tableSums.deductTaxAmount) }}</div>
            <div>发票金额：￥{{ formatMoney(tableSums.amount) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-page" v-loading="sumsLoading">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="queryForm.page" :page-sizes="pageSizes" :page-size="queryForm.size" layout="total, sizes, prev, pager, next" :total="tableSums.total"> </el-pagination>
    </div>
  </div>
</template>

<script>
import { fmtMoney } from '@/assets/js/format-util';
import { FmtEnum } from '@/util/enumUtil';
import printJS from 'print-js';
import SelectButton from '@/components/buttons/SelectButton';
import { tableStyle, pagingMethods, dateOption } from '@/util/mixins';
import { pagingDeductInvoiceAuthenticationList, summaryDeductInvoiceAuthentication, invoiceExportBatch } from '@/service/deduction';
import PHeader from '@/components/pdt/PHeader';

export default {
  name: 'InvoiceQuery',
  components: { PHeader, SelectButton },
  mixins: [tableStyle, pagingMethods, dateOption],
  created() {
    this.submitForm();
  },
  data() {
    return {
      queryForm: {
        taxNo: undefined,
        invoiceCode: undefined,
        invoiceNo: undefined,
        invoiceType: undefined,
        confirmTaxPeriod: undefined,
        sellerName: undefined,
        eInvoiceNo: undefined,
        page: 1,
        size: 30
      },
      invoiceTimeRange: [],
      checkedTimeRange: [],
      dataLoading: false,
      tableData: [],
      sumsLoading: false,
      tableSums: {
        total: 0,
        amount: 0,
        taxAmount: 0,
        deductTaxAmount: 0
      },
      selectExpended: false, //搜索框的展开和收起
      selections: [], //选择框
      invoiceTypes: [{ value: '', label: '全部' }, ...this.$store.state.enums.DeductInvoiceType],
      printData: [],
      printDataTotal: 0,
      printDataCurrent: 1
    };
  },
  methods: {
    handleQuery() {
      this.queryForm.page = 1;
      this.submitForm();
    },
    // 批量打印所有数据
    _printTableAllData() {
      let param = this.getQueryParam();
      param.size = 500;
      param.page = this.printDataCurrent;

      pagingDeductInvoiceAuthenticationList(param).then((res) => {
        if (res.success) {
          const { data = {} } = res;
          const { records = [], total = 0 } = data;
          records.map((item) => {
            const aos = this.$store.state.enums.DeductInvoiceType;
            item.invoiceType = FmtEnum(aos, item.invoiceType);
            item.checkedTime = item.checkedTime || '';
            return item;
          });

          this.printData = [...this.printData, ...records];
          this.printDataTotal = total;
          //一共需要请求几次拿到所有打印数据
          const printDataCount = Math.ceil(this.printDataTotal / 500);
          this.printDataCurrent++;
          if (this.printDataCurrent <= printDataCount) {
            this._printTableAllData();
          }
        }
      });
    },
    printTable() {
      printJS({
        printable: this.printData, //所有数据
        properties: [
          { field: 'invoiceCode', displayName: '发票代码' },
          { field: 'invoiceNo', displayName: '发票号码' },
          { field: 'invoiceType', displayName: '发票类型' },
          { field: 'invoiceTime', displayName: '开票日期' },
          { field: 'sellerName', displayName: '销售方名称' },
          { field: 'amount', displayName: '金额' },
          { field: 'taxAmount', displayName: '税额' },
          { field: 'deductTaxAmount', displayName: '有效税额' },
          { field: 'confirmTaxPeriod', displayName: '所属税期' },
          { field: 'checkedTime', displayName: '勾选日期' }
        ],
        type: 'json',
        gridHeaderStyle: 'border: 1px solid #909399;', //表格头样式
        gridStyle: 'border: 1px solid #909399; text-align:center;', // 表哥体样式
        style: '@media print{@page {size:landscape}}' //横行打印
      });
    },
    //查询
    submitForm() {
      this.getInvoiceList();
    },
    // 重置表单
    resetForm() {
      this.$refs.queryForm.resetFields();
      this.queryForm = {
        taxNo: undefined,
        invoiceCode: undefined,
        invoiceNo: undefined,
        invoiceType: undefined,
        confirmTaxPeriod: undefined,
        sellerName: undefined,
        eInvoiceNo: undefined,
        page: 1,
        size: 30
      };
    },
    getInvoiceList() {
      this.queryForm.taxNo = this.$store.state.deductAuthorize.taxNo;
      if (!this.queryForm.taxNo) {
        this.toast('查询失败，选择的组织非纳税主体', 'warning');
        return;
      }
      const param = this.getQueryParam();
      this.dataLoading = true;
      pagingDeductInvoiceAuthenticationList(param).then((res) => {
        if (res.success) {
          const { data = {} } = res;
          const { records = [] } = data;
          records.map((item) => {
            const aos = this.$store.state.enums.DeductInvoiceType;
            item.invoiceType = FmtEnum(aos, item.invoiceType);
            item.checkedTime = item.checkedTime || '';
            return item;
          });

          this.tableData = records;
        }
        this.dataLoading = false;
      });

      this.sumsLoading = false;
      summaryDeductInvoiceAuthentication(param).then((res) => {
        if (res.success) {
          this.tableSums = res.data;
        }
        this.sumsLoading = false;
      });
    },
    getQueryParam() {
      let queryParam;
      queryParam = this.addDateRange(this.queryForm, this.invoiceTimeRange || ['', ''], 'InvoiceTime');
      queryParam = this.addDateRange(queryParam, this.checkedTimeRange || ['', ''], 'CheckedTime');
      return queryParam;
    },
    /* 表格批量删除筛选框选中 */
    handleSelection(val) {
      this.selections = val;
    },
    /** 批量导出 **/
    async InvoiceExportBatch() {
      if (this.selections.length == 0) {
        this.toast('请勾选需要导出的信息', 'warning');
        return;
      }
      let ids = this.selections.map((i) => i.id);
      this.dataLoading = true;
      const rsp = await invoiceExportBatch(ids);
      this.downloadFile(rsp);
      this.dataLoading = false;
    },
    fmtData(data) {
      return '￥' + data;
    },
    formatMoney(value) {
      return fmtMoney(value);
    }
  }
};
</script>
<style scoped lang="scss">
@import '@/style/select.scss';

.ygx_total_box {
  display: flex;
  padding: 10px 16px;
  border-width: 1px;
  border-radius: 8px;
  border-color: #dddddd;
  border-style: solid;
  margin: 10px 10px 0px 10px;
  span {
    color: #333;
    font-size: 14px;
    margin-right: 48px;
    display: flex;
    align-items: center;
    label {
      font-weight: 800;
      font-size: 16px;
      color: red;
    }
  }
}
.content-select {
  // box-shadow: none;
}

.select-content {
  ::v-deep .select-form {
    width: auto;
    display: block;
  }

  ::v-deep .select-form > div {
    display: inline-flex;
  }
}

.content-main {
  padding: 0px;
  margin: 0px;
  overflow: hidden;

  .content-table {
    .table-statistics {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ebf4ff;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;

      .statistics-left {
        display: flex;
        justify-content: space-between;

        div {
          margin-right: 20px;
        }

        div:first-child {
          margin-left: 10px;
        }
      }

      .statistics-right {
        display: flex;
        justify-content: space-between;

        div {
          margin-left: 20px;
        }

        div:last-child {
          margin-right: 10px;
        }
      }
    }
  }
}

.spilt-line {
  width: 100%;
  height: 1px;
  background: #e9e9e9;
}

.p-header {
  padding: 0px;
  margin: 12px 0px;
}

::v-deep .title {
  padding-top: 6px;
}

::v-deep .el-range-editor--mini .el-range-separator {
  line-height: inherit;
}
</style>
